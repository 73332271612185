import * as React from 'react';
import { Link } from 'react-router-dom';

const menuItems = [
    {
        label: "Hjem",
        url: "/"
    },
    {
        label: "Zoe's Fødselsdag",
        url: "/zoetreaar"
    },
    // {
    //     label: "Familien",
    //     url: "/familien"
    // },
    // {
    //     label: "Arkiv",
    //     url: "/arkiv"
    // }
];

const Layout = ({ children }) => (
    <div>
        {/* <div className="bg-blue-900 text-white text-sm font-mono font-semibold absolute top-0 left-0 z-50">
            <div className="flex">
                {menuItems.map(item => (
                    <div className="flex mr-6 divide-x divide-gray-300">
                        <Link to={item.url} className="hover:text-gray-400">{item.label}</Link>
                    </div>
                ))}
            </div>
        </div> */}
        <div className="z-10 mt-5">
            <React.Suspense fallback={<b>Loading ...</b>}>{children}</React.Suspense>
        </div>
    </div>
);

export default Layout;